// Import our CSS
import "@/scss/app.scss";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import AOS from "aos";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

Alpine.plugin(collapse);

window.addEventListener("load", (event) => {
    AOS.init({
        once: "true",
    });
});

window.Alpine = Alpine;
Alpine.start();
